import router from 'next/router';
import { generateAIRoutines } from 'utils/generateAIRoutines';
import React, { useEffect, useState } from 'react';
import { stores } from 'state/stores';
import API from 'utils/API';
import { FormInput } from 'styleguide/Inputs';
import { DisplayXS, TextMD } from 'styleguide/Texts';
import { PrimaryButton } from 'styleguide/Buttons';
import { MIN_WIDTH_FOR_SIDEBAR_NAVIGATION } from 'styleguide/theme';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Multiselect, Select } from 'components/Multiselect';
import { DividerLine } from 'styleguide/DividerLine';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { FlexRow } from 'styleguide/Row';
import { ThemedIcon } from 'styleguide/ThemedIcon';

const ScreenWrapper = styled.div`
  @media (min-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    width: 100vw;
    height: 100vh;
  }
  overflow-y: scroll;
`;

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px 20px;
  min-height: 90vh;
`;

const BackButton = styled(View)`
  padding-right: ${Spacing.md}px;
  cursor: pointer;
`;
export const lowercaseKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => lowercaseKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = lowercaseKeys(obj[key]);
      return acc;
    }, {} as { [key: string]: any });
  }
  return obj;
};

interface GenerateRoutineScreenProps {}

export const GenerateRoutineScreen: React.FC<GenerateRoutineScreenProps> = ({}) => {
  const [userInput, setUserInput] = useState('');
  const [age, setAge] = useState(0);
  const [sex, setSex] = useState('');
  const [primaryGoal, setPrimaryGoal] = useState('');
  const [amountOfChange, setAmountOfChange] = useState(0);
  const [availability, setAvailability] = useState(0);
  const [equipment, setEquipment] = useState('');
  const [isGenerating, setIsGenerating] = useState('');

  const [progress, setProgress] = useState(0);
  const updateProgressBar = (progress: number) => {
    setProgress(progress);
  };

  useEffect(() => {
    setUserInput(
      ` Create a weightlifitng workout plan for a ${sex} of age ${age} who wants to ${primaryGoal}. He is available to workout ${availability} days per week and has access to ${equipment}.`,
    );
    console.log(userInput);
  }, [age, sex, primaryGoal, amountOfChange, availability, equipment]);

  const handleClick = async (userInput: string) => {
    try {
      setProgress(0);
      setIsGenerating('Generating routine...');

      const response = lowercaseKeys(
        JSON.parse(await generateAIRoutines(userInput, updateProgressBar)),
      );

      console.log(response);

      const routineIdArray: string[] = [];

      // creates routines and pushes them to "My Routines"
      for (const routineUpdate of response) {
        const response = await API.postRoutine(routineUpdate);
        routineIdArray.push(response.data.routineId);
      }

      const existingRoutineFolder = stores.routineFolders.folders.find(
        folder => folder.title === `${primaryGoal} by ${amountOfChange} lbs`,
      );

      console.log(existingRoutineFolder);

      // If "Generated Routines" folder doesn't exist, it creates it and if it does, it fetches it
      await stores.routineFolders.fetch();
      if (!existingRoutineFolder) {
        await API.postRoutineFolder({
          title: `${primaryGoal} ${amountOfChange !== 0 || null ? `by ${amountOfChange} lbs` : ''}`,
        });
      } else {
        await API.deleteRoutineFolder(existingRoutineFolder.id);
        await API.postRoutineFolder({
          title: `${primaryGoal} ${amountOfChange !== 0 || null ? `by ${amountOfChange} lbs` : ''}`,
        });
      }

      await stores.routineFolders.fetch();

      const routineFolder = stores.routineFolders.folders.find(
        folder =>
          folder.title ===
          `${primaryGoal} ${amountOfChange !== 0 || null ? `by ${amountOfChange} lbs` : ''}`,
      );

      if (!routineFolder) {
        throw new Error('Unable to find just-created routine folder');
      }
      // mapping the routineIdArray to create an array of RoutineUpdateLocation objects
      const routineUpdateLocations = routineIdArray.map((routineId, index) => {
        return {
          routineId,
          folderId: routineFolder.id,
          index: index,
        };
      });

      await API.updateRoutineLocations(routineUpdateLocations);

      await stores.routines.fetch();
      await stores.routineFolders.fetch();

      setProgress(100);

      router.push('/routines');
    } catch (error) {
      console.error(`Error generating the output: ${error}`);
      toast.error(`Error generating the output: ${(error as Error).toString()}`);
    }
  };

  return (
    <ScreenWrapper>
      <Container>
        <FlexRow
          style={{
            marginBottom: '20px',
          }}
        >
          <BackButton onClick={() => router.push('/routines')}>
            <ThemedIcon type="back-button" />
          </BackButton>
          <DisplayXS>Generate Routine</DisplayXS>
        </FlexRow>
        <TextMD
          style={{
            margin: '10px 0px',
          }}
        >
          Age
        </TextMD>
        <FormInput
          type="number"
          name="age"
          id="age"
          min="13"
          max="100"
          onChange={e => setAge(e.target.valueAsNumber)}
        ></FormInput>
        <TextMD
          style={{
            margin: '10px 0px',
          }}
        >
          Sex
        </TextMD>
        <Select
          styles={{
            valueContainer(base) {
              return {
                ...base,
                backgroundColor: 'white',
              };
            },
            dropdownIndicator(base) {
              return {
                ...base,
                color: '#1780EA',
              };
            },
            indicatorSeparator(base) {
              return {
                ...base,
                backgroundColor: 'white',
              };
            },

            placeholder(base) {
              return {
                ...base,
                color: 'black',
              };
            },
          }}
          options={[
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
          ]}
          onChange={(e: any) => {
            console.log(e.value);
            setSex(e.value);
          }}
        ></Select>
        <TextMD
          style={{
            margin: '10px 0px',
          }}
        >
          What's your primary goal?
        </TextMD>
        <Select
          styles={{
            valueContainer(base) {
              return {
                ...base,
                backgroundColor: 'white',
              };
            },
            dropdownIndicator(base) {
              return {
                ...base,
                color: '#1780EA',
              };
            },
            indicatorSeparator(base) {
              return {
                ...base,
                backgroundColor: 'white',
              };
            },
            placeholder(base) {
              return {
                ...base,
                color: 'black',
              };
            },
          }}
          options={[
            { value: 'Lose Fat', label: 'Lose Fat' },
            { value: 'Gain Muscle', label: 'Gain Muscle' },
            { value: 'Increase Strength', label: 'Increase Strength' },
            { value: 'Maintain Weight', label: 'Maintain Weight' },
          ]}
          onChange={(e: any) => {
            console.log(e.value);
            setPrimaryGoal(e.value);
          }}
        ></Select>
        <TextMD
          style={{
            margin: '10px 0px',
          }}
        >
          How much weight (lbs) do you want to gain or lose? (leave blank if you want to maintain
          weight or increase strength)
        </TextMD>
        <FormInput
          type="number"
          name="amount-of-change"
          id="amount-of-change"
          min="1"
          onChange={e => setAmountOfChange(e.target.valueAsNumber)}
        ></FormInput>
        <TextMD
          style={{
            margin: '10px 0px',
          }}
        >
          How many days of the week are you available to work out? (minimum of 3 days recommended
          for optimal results)
        </TextMD>
        <FormInput
          type="number"
          name="availability"
          id="availability"
          min="2"
          max="7"
          onChange={e => {
            console.log(e.target.valueAsNumber);
            setAvailability(e.target.valueAsNumber);
          }}
        ></FormInput>
        <TextMD
          style={{
            margin: '10px 0px',
          }}
        >
          Which of the following equipment do you have access to? (select all that apply by clicking
          and dragging)
        </TextMD>
        <Multiselect
          styles={{
            valueContainer(base) {
              return {
                ...base,
                backgroundColor: 'white',
              };
            },
            dropdownIndicator(base) {
              return {
                ...base,
                color: '#1780EA',
              };
            },
            indicatorSeparator(base) {
              return {
                ...base,
                backgroundColor: 'white',
              };
            },
            placeholder(base) {
              return {
                ...base,
                color: 'black',
              };
            },
          }}
          options={[
            { value: 'Dumbbells', label: 'Dumbbells' },
            { value: 'Barbells', label: 'Barbells' },
            { value: 'Kettlebells', label: 'Kettlebells' },
            { value: 'Machines', label: 'Machines' },
            { value: 'Cables', label: 'Cables' },
            { value: 'Resistance Bands', label: 'Resistance Bands' },
          ]}
          onChange={(e: any) => {
            console.log(JSON.stringify(e.map((option: any) => option.value)));
            setEquipment(
              e.length === 6 ? 'everything' : JSON.stringify(e.map((option: any) => option.value)),
            );
          }}
        ></Multiselect>
        <DividerLine
          style={{
            margin: '20px 0',
          }}
        ></DividerLine>
        <PrimaryButton
          type="submit"
          title="Generate Routine"
          enabled={true}
          onClick={() => {
            handleClick(userInput);
          }}
          style={{
            width: '100%',
            margin: '10px 0',
          }}
        ></PrimaryButton>
        <TextMD
          style={{
            margin: '10px 0',
          }}
        >
          {isGenerating}
        </TextMD>
        <View
          id="progress-bar"
          style={{
            width: '100%',
            height: '30px',
            backgroundColor: '#e6e6e6',
            borderRadius: '10px',
            marginBottom: '10px',
          }}
        >
          <View
            id="progress-bar-fill"
            style={{
              height: '100%',
              backgroundColor: '#1780EA',
              borderRadius: '10px',
              width: `${progress}%`,
            }}
          ></View>
        </View>
      </Container>
    </ScreenWrapper>
  );
};
