import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import { Card } from 'styleguide/Card';
import { colors } from 'styleguide/colors';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { H1, TextMD } from 'styleguide/Texts';
import { ThemedIcon } from 'styleguide/ThemedIcon';
import { View } from 'styleguide/View';
import { CropImage } from '../CropImage/CropImage';
import { Input } from 'styleguide/Inputs';
import { Select, Multiselect, isUpdate } from 'components/Multiselect';
import { PrimaryButton } from 'styleguide/Buttons';
import { ExerciseImage } from 'components/ExerciseImage';
import { CustomExerciseViewModel } from './CustomExerciseViewModel';
import { Spacing } from 'styleguide/spacing';
import { Icon } from 'styleguide/Icon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 500px;
  height: 90vh;
  max-height: 700px;
  @media (max-width: 1024px) {
    width: 80vw;
  }
  overflow-y: auto;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CameraCircle = styled(Card)`
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  color: ${colors.primary500};
  cursor: pointer;
`;

const TitleInput = styled(Input)`
  background-color: transparent;
  font-size: 20px;
`;

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.md}px;
`;

const CellContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
`;

const DismissButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding: ${Spacing.xs}px;
`;

export interface CustomExerciseModalProps {
  vm: CustomExerciseViewModel;
  isOpen: boolean;
  onClose: () => void;
}

export const CustomExerciseModal = observer(({ vm, isOpen, onClose }: CustomExerciseModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        {vm.imageUrlToCrop ? (
          <CropContainer>
            <CropImage
              imageUrl={vm.imageUrlToCrop}
              onImageCropped={vm.onUploadImage}
              onCancel={vm.onCancelImageCrop}
            />
          </CropContainer>
        ) : (
          <>
            <Top>
              <H1>Create Exercise</H1>
              <DismissButtonContainer onClick={onClose}>
                <Icon type="xSmallGrey" />
              </DismissButtonContainer>
            </Top>
            <View style={{ alignItems: 'center', padding: '16px 0' }}>
              <Label htmlFor="file-upload">
                <CameraCircle>
                  <ImageComponent
                    isLoadingImage={vm.isUploadingImage}
                    remoteThumbnailUrl={vm.remoteThumbnailUrl}
                  />
                </CameraCircle>
              </Label>
              <Label htmlFor="file-upload">
                <input
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={e => {
                    const file = e?.target.files?.[0];
                    if (file) {
                      vm.onFileSystemImageSelected(file);
                    }
                  }}
                />
                Add Image
              </Label>
            </View>
            <CellContainer>
              <View style={{ backgroundColor: undefined }}>
                <TitleInput
                  placeholder="Exercise Name"
                  value={vm.title}
                  onChange={e => vm.onTitleUpdate(e.currentTarget.value)}
                />
              </View>
              <DividerLine style={{ marginTop: 16 }} />
            </CellContainer>
            <CellContainer>
              <CellContent>
                <TextMD>Exercise Type</TextMD>
                <View style={{ width: 200 }}>
                  <Select
                    value={vm.selectedExerciseType}
                    onChange={e => {
                      if (isUpdate(e) || e === undefined) {
                        vm.onExerciseTypeUpdate(e);
                      }
                    }}
                    options={vm.exerciseTypeOptions}
                  />
                </View>
              </CellContent>
              <DividerLine style={{ marginTop: 16 }} />
            </CellContainer>
            <CellContainer>
              <CellContent>
                <TextMD>Equipment</TextMD>
                <View style={{ width: 200 }}>
                  <Select
                    options={vm.equipmentOptions}
                    value={vm.selectedEquipment}
                    onChange={e => {
                      if (isUpdate(e) || e === undefined) {
                        vm.onEquipmentUpdate(e);
                      }
                    }}
                  />
                </View>
              </CellContent>
              <DividerLine style={{ marginTop: 16 }} />
            </CellContainer>
            <CellContainer>
              <CellContent>
                <TextMD>Primary Muscle Group</TextMD>
                <View style={{ width: 200 }}>
                  <Select
                    options={vm.muslceGroupOptions}
                    value={vm.selectedMuslceGroup}
                    menuPlacement={'top'}
                    onChange={e => {
                      if (isUpdate(e) || e === undefined) {
                        vm.onMuscleGroupUpdate(e);
                      }
                    }}
                  />
                </View>
              </CellContent>
              <DividerLine style={{ marginTop: 16 }} />
            </CellContainer>
            <CellContainer>
              <CellContent>
                <TextMD>Other Muscles</TextMD>
                <View style={{ width: 200 }}>
                  <Multiselect
                    options={vm.secondaryMuscleGroupOptions}
                    value={vm.selectedSecondaryMuscleGroups}
                    menuPlacement={'top'}
                    onChange={(e: any) => vm.onSecondaryMuscleGroupUpdate(e)}
                  />
                </View>
              </CellContent>
              <DividerLine style={{ marginTop: 16 }} />
            </CellContainer>
            <View
              style={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                padding: '32px 0 16px 0',
              }}
            >
              <PrimaryButton
                style={{ width: 200 }}
                title="Save Exercise"
                onClick={async () => {
                  try {
                    await vm.onSave();
                    onClose();
                  } catch {
                    //
                  }
                }}
                enabled={vm.isSaveEnabled}
                loading={vm.isSaving}
              />
            </View>
          </>
        )}
      </Container>
    </Modal>
  );
});

interface ImageComponentProp {
  isLoadingImage: boolean;
  remoteThumbnailUrl?: string;
}

const ImageComponent = ({ isLoadingImage, remoteThumbnailUrl }: ImageComponentProp) => {
  if (isLoadingImage) {
    return <ClipLoader size={20} color={colors.primary500} loading={true} />;
  }

  if (!!remoteThumbnailUrl) {
    return <ExerciseImage source={remoteThumbnailUrl} diameter={80} />;
  }

  return <ThemedIcon type="camera" />;
};
