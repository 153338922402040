import { Sex } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import OpenAI from 'openai';
import { lowercaseKeys } from 'screens/CreateRoutine/GenerateRoutineScreen';
import { stores } from 'state/stores';
import { ChatGoal } from 'state/stores/chatContext';
import API from 'utils/API';

const openai = new OpenAI({
  apiKey: 'sk-proj-GAYjv6EPgj8ouW2sdQZBT3BlbkFJJTtxWdw3ElF4iZab54rO',
  dangerouslyAllowBrowser: true,
});

export interface Message {
  text: string;
  id: string;
  sender: 'chatbot' | 'user';
}
export class ChatbotViewModel {
  constructor() {
    makeAutoObservable(this);
  }

  get isChatContextConfigured() {
    return (
      this.userGoal !== undefined && this.userSex !== undefined && this.chatbotTone !== undefined
    );
  }

  get userGoal(): ChatGoal | undefined {
    return stores.chatContext.userGoal;
  }
  get userSex(): Sex | undefined {
    return stores.chatContext.userSex;
  }

  get threadId() {
    return stores.chatContext.threadId;
  }

  get messages(): Message[] {
    return stores.chatContext.messages || [];
  }

  get chatbotTone(): string {
    return stores.chatContext.chatbotTone || '';
  }

  onSendPress = (userInput: string) => {
    this.onUpdateMessages({ text: userInput, id: Date.now().toString(), sender: 'user' });
  };

  onUpdatedGoal = (goal: ChatGoal) => {
    stores.chatContext.setUserGoal(goal);
  };

  onUpdatedSex = (sex: Sex) => {
    stores.chatContext.setUserSex(sex);
  };

  onUpdateMessages = (message: Message) => {
    this.messages.push(message);
    stores.chatContext.setMessages(this.messages);
  };

  onUpdatedChatbotTone = (tone: string) => {
    stores.chatContext.setChatbotTone(tone);
  };

  onUpdateRoutineClicked = async (numberOfWorkouts: number) => {
    const workouts = JSON.stringify(await this.getWorkouts(numberOfWorkouts));
    console.log(workouts);
    await this.updateRoutine(workouts);
  };

  getWorkouts = async (numberOfWorkouts: number) => {
    for (let i = 0; i <= numberOfWorkouts; i++) {
      await stores.workouts.fetchMoreUserWorkouts(stores.account.username);
    }
    const workouts = stores.workouts.getUserWorkouts(stores.account.username);
    return workouts.slice(0, numberOfWorkouts);
  };

  getAssistant = async () => {
    const assistantNames = [];
    const response = (await openai.beta.assistants.list()).data;
    for (const assistant of response) {
      assistantNames.push(assistant.name);
    }

    const username = stores.account.username;
    let assistant;

    if (!assistantNames.includes(username)) {
      assistant = await openai.beta.assistants.create({
        name: username,
        model: 'gpt-4o',
        instructions: `You are a world class fitness trainer and you're here to help users with their fitness goals and to answer any questions they have. 
        The user's sex is ${this.userSex} and their goal is to ${this.userGoal}. 
        Provide your responses in the following tone: ${this.chatbotTone}. 
        THE OUTPUT SHOULD ONLY CONSISTENT OF RAW TEXT, NO OTHER SPECIAL CHARACTERS.`,
      });
    } else {
      assistant = await openai.beta.assistants.retrieve(
        response.find(assistant => assistant.name === username)?.id || '',
      );
    }
    return assistant;
  };

  getThread = async (userInput: string) => {
    let thread;
    if (!this.threadId) {
      thread = await openai.beta.threads.create({
        messages: [
          {
            role: 'user',
            content: `${userInput}`,
          },
        ],
      });
      stores.chatContext.setThreadId(thread.id);
    } else {
      thread = await openai.beta.threads.retrieve(this.threadId || '');
    }
    return thread;
  };

  getFeedback = async (workouts: string, numberOfWorkouts: number) => {
    const assistant = await this.getAssistant();

    await openai.beta.assistants.update(assistant.id, {
      model: 'gpt-4o',
      instructions: `You are a world class fitness trainer, you're also mean like an stereotypical army drill sergent and you are here to provide feedback on the user\'s previous ${
        numberOfWorkouts !== 1 ? `${numberOfWorkouts} workouts` : `workout`
      }, which will be given in a JSON format. Do not give generic feedback, give very tailored and specific feedback based on the number of sets and reps the user performed, along with the weights. Give suggestions on which exercises they should progress their volume or weight in. The user's sex is ${
        this.userSex
      } and their goal is to ${this.userGoal}. You should respond in the following tone: ${
        this.chatbotTone
      }. A few things to keep in mind: 
      1. Don't suggest simply increasing the weight for each exercise - only do that if all sets of an exercise have been performed with the same weight and reps. 
      2. Only suggest increasing the weight by an amount that's actually feasible on machines or barbells such as 2.5 lbs or 5 lbs (1 or 2.2 kg). 
      3. Suggest other forms of progressive overload such as controlling the eccentric, or increasing the number of reps at the same weight.`,
    });

    const thread = await this.getThread(workouts);

    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: workouts,
    });

    let output = '';

    const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
      assistant_id: assistant.id,
    });

    if (run.status === 'completed') {
      const messages = await openai.beta.threads.messages.list(run.thread_id);
      for (const message of messages.data.reverse()) {
        if (message.content[0].type === 'text') {
          console.log(`${message.content[0].text?.value}`);
          output = message.content[0].text.value;
        }
      }
    } else {
      console.log(run.status);
    }
    this.onUpdateMessages({
      text: output,
      id: Date.now().toString(),
      sender: 'chatbot',
    });
  };

  updateRoutine = async (workouts: string) => {
    const assistant = await this.getAssistant();

    await openai.beta.assistants.update(assistant.id, {
      model: 'gpt-4o',
      instructions: `Based on the feedback you gave, update the user's given previous workouts (provided in JSON format) and output the new routine in the following format:

###

        [
    {
        "Title": "Upper Body Strength",
        "Exercises": [
            {
                "name": "Bench Press (Barbell)",
                "exercise_template_id": "79D0BB3A",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Pull Up",
                "exercise_template_id": "1B2B1E7C",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            }, 
            {
                "name": "Shoulder Press (Dumbbell)",
                "exercise_template_id": "878CD1D0",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Bent Over Row (Barbell)",
                "exercise_template_id": "55E6546F",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Triceps Extension (Cable)",
                "exercise_template_id": "21310F5F",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Bicep Curl (Dumbbell)",
                "exercise_template_id": "37FCC2BB",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            }
        ]
    },
    {
        "Title": "Lower Body Strength",
        "Exercises": [
            {
                "name": "Squat (Barbell)",
                "exercise_template_id": "A1B2C3D4",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Lunges (Dumbbell)",
                "exercise_template_id": "E5F6G7H8",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Leg Press (Machine)",
                "exercise_template_id": "I9J0K1L2",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Calf Raise (Machine)",
                "exercise_template_id": "M3N4O5P6",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 12, "end": 15 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 12, "end": 15 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 12, "end": 15 } }
                ],
                "rest_seconds": 60
            }
        ]
    },
    {
        "Title": "Full Body Strength",
        "Exercises": [
            {
                "name": "Deadlift (Barbell)",
                "exercise_template_id": "Q7R8S9T0",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Overhead Press (Barbell)",
                "exercise_template_id": "U1V2W3X4",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Bent Over Row (Dumbbell)",
                "exercise_template_id": "Y5Z6A7B8",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Lateral Raise (Dumbbell)",
                "exercise_template_id": "C9D0E1F2",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Triceps Dip",
                "exercise_template_id": "G3H4I5J6",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            }
        ]
    }
]

        ###
        
        THE OUTPUT SHOULD ONLY CONTAIN A JSON OBJECT, NO OTHER SPECIAL CHARACTERS. DO NOT INCLUDE ANY TEXT BEFORE OR AFTER THE JSON OBJECT. IMPORTANT: DO NOT INCLUDE ANY FORMATTING TAGS.`,
    });

    const thread = await this.getThread(workouts);

    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: workouts,
    });

    let output = '';

    const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
      assistant_id: assistant.id,
    });

    console.log('Starting process... ');
    if (run.status === 'completed') {
      const messages = await openai.beta.threads.messages.list(run.thread_id);
      for (const message of messages.data.reverse()) {
        if (message.content[0].type === 'text') {
          console.log(`${message.content[0].text?.value}`);
          output = message.content[0].text.value;
          output = `${
            output ? !output.startsWith('j') : output.replace('json```', '').replace('```', '')
          }`;
        }
      }
      console.log('Output:', output);
      await stores.routines.fetch();
      console.log('step1 done');
      const workoutsLength = JSON.parse(workouts).length;
      console.log('Workouts length:', workoutsLength);
      const prevRoutine = await stores.routines.getRoutine(JSON.parse(workouts)[0].routine_id);
      console.log('Previous routine title:', prevRoutine.title);

      const data = await lowercaseKeys(JSON.parse(output)).map((routine: any) => ({
        ...routine,
        index: prevRoutine.index,
        folder_id: prevRoutine?.folder_id ? prevRoutine.folder_id : null,
      }));

      console.log('Data.routine:', data[0]);
      console.log('Data:', data);

      await API.deleteRoutine(prevRoutine?.id);
      await API.postRoutine(data[0]);
    } else {
      console.log(run.status);
    }
  };

  addRoutine = async (userInput: string) => {
    const assistant = await this.getAssistant();

    await openai.beta.assistants.update(assistant.id, {
      model: 'gpt-4o',
      instructions: `You are a world class fitness trainer and you're here to help users with their fitness goals and to answer any questions they have. 
      Output a JSON object that contains the new routine tailored to the user in the following format:
      ###

        [
    {
        "Title": "Upper Body Strength",
        "Exercises": [
            {
                "name": "Bench Press (Barbell)",
                "exercise_template_id": "79D0BB3A",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Pull Up",
                "exercise_template_id": "1B2B1E7C",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            }, 
            {
                "name": "Shoulder Press (Dumbbell)",
                "exercise_template_id": "878CD1D0",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Bent Over Row (Barbell)",
                "exercise_template_id": "55E6546F",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Triceps Extension (Cable)",
                "exercise_template_id": "21310F5F",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Bicep Curl (Dumbbell)",
                "exercise_template_id": "37FCC2BB",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            }
        ]
    },
    {
        "Title": "Lower Body Strength",
        "Exercises": [
            {
                "name": "Squat (Barbell)",
                "exercise_template_id": "A1B2C3D4",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Lunges (Dumbbell)",
                "exercise_template_id": "E5F6G7H8",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Leg Press (Machine)",
                "exercise_template_id": "I9J0K1L2",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Calf Raise (Machine)",
                "exercise_template_id": "M3N4O5P6",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 12, "end": 15 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 12, "end": 15 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 12, "end": 15 } }
                ],
                "rest_seconds": 60
            }
        ]
    },
    {
        "Title": "Full Body Strength",
        "Exercises": [
            {
                "name": "Deadlift (Barbell)",
                "exercise_template_id": "Q7R8S9T0",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Overhead Press (Barbell)",
                "exercise_template_id": "U1V2W3X4",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 6, "end": 8 } }
                ],
                "rest_seconds": 90
            },
            {
                "name": "Bent Over Row (Dumbbell)",
                "exercise_template_id": "Y5Z6A7B8",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 8, "end": 10 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Lateral Raise (Dumbbell)",
                "exercise_template_id": "C9D0E1F2",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            },
            {
                "name": "Triceps Dip",
                "exercise_template_id": "G3H4I5J6",
                "sets": [
                    { "index": 0, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 1, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } },
                    { "index": 2, "indicator": "normal", "rep_range": { "start": 10, "end": 12 } }
                ],
                "rest_seconds": 60
            }
        ]
    }
]

        ###
        
        Get the exercise_template_id's from the file attached. THE OUTPUT SHOULD ONLY CONTAIN A JSON OBJECT, NO OTHER SPECIAL CHARACTERS. DO NOT INCLUDE ANY TEXT BEFORE OR AFTER THE JSON OBJECT. IMPORTANT: DO NOT INCLUDE ANY FORMATTING TAGS.
      `,
      tools: [{ type: 'file_search' }],
      tool_resources: {
        file_search: {
          vector_store_ids: ['vs_qzOBDbc2fQKsadrguE6yHs9g'],
        },
      },
    });
    const thread = await this.getThread(userInput);

    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: userInput,
    });

    let output = '';

    console.log('thread retrieved');

    const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
      assistant_id: assistant.id,
    });

    if (run.status === 'completed') {
      const messages = await openai.beta.threads.messages.list(run.thread_id);
      for (const message of messages.data.reverse()) {
        if (message.content[0].type === 'text') {
          console.log(`${message.content[0].text?.value}`);
          output = message.content[0].text.value;
          output = `${
            output ? !output.startsWith('j') : output.replace('json```', '').replace('```', '')
          }`;
        }
      }
    } else {
      console.log(run.status);
    }

    const data = await lowercaseKeys(JSON.parse(output)).map((routine: any) => ({
      ...routine,
      index: 1,
    }));

    console.log('Data:', data);
    await API.postRoutine(lowercaseKeys(data[0]));
  };

  sendMessage = async (userInput: string) => {
    const assistant = await this.getAssistant();

    await openai.beta.assistants.update(assistant.id, {
      model: 'gpt-4o',
      temperature: 0,
      instructions: `You are a world class fitness trainer and you're here to help users with their fitness goals and to answer any questions they have. The user's sex is ${this.userSex} and their goal is to ${this.userGoal}. Provide your responses in the following tone: ${this.chatbotTone}.
      IMPORTANT: check if the user is asking for a workout or routine suggesstion in their request. If they are, start the output with the following sequence: 'NEW ROUTINE:' and make it a heading. In addition, if the user does ask for a routine or workout suggestion, choose only from the exercises in the vector store attached. Keep the number of exercises around 5-6 and the total workout time under an hour.`,
      tools: [{ type: 'file_search' }],
      tool_resources: {
        file_search: {
          vector_store_ids: ['vs_qzOBDbc2fQKsadrguE6yHs9g'],
        },
      },
    });
    const thread = await this.getThread(userInput);

    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: userInput,
    });

    let output = '';

    const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
      assistant_id: assistant.id,
    });

    if (run.status === 'completed') {
      const messages = await openai.beta.threads.messages.list(run.thread_id);
      for (const message of messages.data.reverse()) {
        if (message.content[0].type === 'text') {
          console.log(`${message.content[0].text?.value}`);
          output = message.content[0].text.value;
        }
      }
    } else {
      console.log(run.status);
    }
    this.onUpdateMessages({
      text: output,
      id: Date.now().toString(),
      sender: 'chatbot',
    });
  };
}
