import { Message } from 'components/Chatbot/ChatbotViewModel';
import { Sex } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
export type ChatGoal = 'build muscle' | 'lose weight' | 'increase strength';

const CHAT_CONTEXT_LOCAL_STORAGE_KEY = 'CHAT_CONTEXT_LOCAL_STORAGE_KEY';
interface ChatContextState {
  userSex?: Sex;
  userGoal?: ChatGoal;
  threadId?: string;
  messages?: Message[];
  chatbotTone?: string;
}

export class ChatContext {
  private _state: ChatContextState = {};

  get userGoal() {
    return this._state.userGoal;
  }
  get userSex() {
    return this._state.userSex;
  }

  get threadId() {
    return this._state.threadId;
  }

  get messages() {
    return this._state.messages;
  }

  get chatbotTone() {
    return this._state.chatbotTone;
  }

  setUserGoal = (goal: ChatGoal) => {
    this._state.userGoal = goal;
    this.persist();
  };

  setUserSex = (sex: Sex) => {
    this._state.userSex = sex;
    this.persist();
  };

  setThreadId = (threadId: string) => {
    this._state.threadId = threadId;
    this.persist();
  };

  setMessages = (messages: Message[]) => {
    this._state.messages = messages;
    this.persist();
  };

  setChatbotTone = (tone: string) => {
    this._state.chatbotTone = tone;
    this.persist();
  };

  constructor() {
    makeAutoObservable(this);
  }

  private persist = () => {
    window.localStorage.setItem(CHAT_CONTEXT_LOCAL_STORAGE_KEY, JSON.stringify(this._state));
  };

  hydrate = () => {
    const chatContextJSON = window.localStorage.getItem(CHAT_CONTEXT_LOCAL_STORAGE_KEY);
    if (!!chatContextJSON) {
      this._state = JSON.parse(chatContextJSON);
    }
  };

  clearData = () => {
    this._state = {};
    this.persist();
  };
}
