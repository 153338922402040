import React from 'react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { View } from 'styleguide/View';
import { ChatbotViewModel } from './ChatbotViewModel';
import { PrimaryButton } from 'styleguide/Buttons';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { FormInput } from 'styleguide/Inputs';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import ReactMarkdown from 'react-markdown';
import { ClipLoader } from 'react-spinners';
import { Select } from 'components/Multiselect';

const Header = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.sm + Spacing.xs}px;
  cursor: pointer;
`;

const UserMessage = styled.div`
  background-color: #1780ea;
  padding: 10px;
  display: flex;
  justify-content: end;
  align-items: right;
  margin: 10px;
  max-width: 50%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const SystemMessage = styled.div`
  background-color: #ecedf0;
  padding: 10px;
  display: block;
  justify-content: left;
  margin: 10px;
  max-width: 80%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const UserMessageParent = styled.div`
  display: flex;
  justify-content: end;
`;

const MessagesContainer = styled.div`
  flex-direction: column-reverse;
  display: flex;
  border: 1px solid #ecedf0;
  border-radius: 10px;
  margin-top: 10px;
  overflow-y: scroll;
`;

const MediumHevyActivityIndicator = ({ overrideColor }: { overrideColor?: string }) => {
  return <ClipLoader size={20} color={overrideColor ?? colors.primary500} loading={true} />;
};

const ActivityIndicatorWrapper = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const ChatbotView = observer(() => {
  const [userInput, setUserInput] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRoutineUpdateAvailable, setIsRoutineUpdateAvailable] = useState(false);
  const [isAddRoutineAvailable, setIsAddRoutineAvailable] = useState(false);
  const [prevUserInput, setPrevUserInput] = useState('');
  const [vm] = useState(new ChatbotViewModel());
  const handleSendEvent = async () => {
    console.log(vm.userSex);
    console.log(vm.userGoal);
    setIsLoading(true);
    setUserInput('');
    vm.onSendPress(userInput);
    await vm.sendMessage(userInput);
    setIsLoading(false);
    console.log(vm.messages);
    vm.messages[vm.messages.length - 1].text.startsWith('NEW ROUTINE:')
      ? setIsAddRoutineAvailable(true)
      : null;
  };

  return (
    <View
      style={{
        position: 'absolute',
        bottom: 0,
        right: 25,
        backgroundColor: 'white',
        padding: '20px',
        width: '21%',
        maxHeight: '90%',
        borderRadius: '10px',
        border: '1px solid #ecedf0',
        zIndex: 100,
      }}
    >
      <Header
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <TextMD
          style={{
            justifyContent: '',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          Chat with HevyAgent
        </TextMD>
      </Header>
      {!isCollapsed && (
        <>
          {vm.isChatContextConfigured ? (
            <>
              <MessagesContainer>
                {isLoading && (
                  <ActivityIndicatorWrapper>
                    <MediumHevyActivityIndicator />
                  </ActivityIndicatorWrapper>
                )}
                {vm.messages &&
                  vm.messages
                    .slice()
                    .reverse()
                    .map((message, index) => {
                      if (message.sender === 'chatbot') {
                        return (
                          <SystemMessage key={index}>
                            <TextSMRegular style={{ color: '#111826' }}>
                              <ReactMarkdown>{message.text}</ReactMarkdown>
                            </TextSMRegular>
                          </SystemMessage>
                        );
                      } else {
                        return (
                          <UserMessageParent key={index}>
                            <UserMessage>
                              <TextSMRegular style={{ color: 'white' }}>
                                <ReactMarkdown>{message.text}</ReactMarkdown>
                              </TextSMRegular>
                            </UserMessage>
                          </UserMessageParent>
                        );
                      }
                    })}
              </MessagesContainer>
              {isRoutineUpdateAvailable && (
                <PrimaryButton
                  title={'Update Routine Based on Feedback'}
                  style={{
                    margin: '10px 0 10px 0',
                    minHeight: '30px',
                  }}
                  onClick={async () => {
                    vm.onSendPress('Update my routine based on your feedback.');
                    setIsLoading(true);
                    await vm.onUpdateRoutineClicked(1);
                    vm.onUpdateMessages({
                      text: 'Routine has been updated. Note that this feedback is not perfect and is only based on your last workout. Feel free to make any changes that you feel are necessary.',
                      id: Date.now().toString(),
                      sender: 'chatbot',
                    });
                    setIsLoading(false);
                    setIsRoutineUpdateAvailable(false);
                  }}
                ></PrimaryButton>
              )}
              {isAddRoutineAvailable && (
                <PrimaryButton
                  title={'Add Routine to Library'}
                  style={{
                    margin: '10px 0 10px 0',
                    minHeight: '30px',
                  }}
                  onClick={async () => {
                    console.log(prevUserInput);
                    vm.onSendPress('Add this routine to my library.');
                    setIsLoading(true);
                    await vm.addRoutine(prevUserInput);
                    setPrevUserInput('');
                    setIsLoading(false);
                    vm.onUpdateMessages({
                      text: 'Routine added to library.',
                      id: Date.now().toString(),
                      sender: 'chatbot',
                    });
                    setIsAddRoutineAvailable(false);
                  }}
                ></PrimaryButton>
              )}
              <FormInput
                placeholder="Chat with HevyAgent"
                onChange={e => {
                  setUserInput(e.target.value);
                  setPrevUserInput(e.target.value);
                }}
                value={userInput}
                style={{
                  marginTop: '10px',
                }}
                onKeyDown={async e => {
                  if (e.key === 'Enter') {
                    handleSendEvent();
                  }
                }}
              ></FormInput>
              <PrimaryButton
                title={'Send'}
                style={{
                  margin: '10px 0 10px 0',
                  minHeight: '30px',
                }}
                onClick={async () => {
                  handleSendEvent();
                }}
              ></PrimaryButton>
              <PrimaryButton
                style={{
                  marginBottom: '10px',
                  minHeight: '30px',
                }}
                title={'Get Feedback on Last Workout'}
                onClick={async () => {
                  vm.onSendPress('Give me feedback on my last workout.');
                  setIsLoading(true);
                  const workouts = JSON.stringify(await vm.getWorkouts(1));
                  await vm.getFeedback(workouts, 1);
                  setIsLoading(false);
                  setIsRoutineUpdateAvailable(true);
                }}
              ></PrimaryButton>
              <PrimaryButton
                style={{
                  marginBottom: '10px',
                  minHeight: '30px',
                }}
                title={'Get Feedback on Last 3 Workouts'}
                onClick={async () => {
                  vm.onSendPress('Give me feedback on my last 3 workouts.');
                  setIsLoading(true);
                  const workouts = JSON.stringify(await vm.getWorkouts(3));
                  await vm.getFeedback(workouts, 3);
                  setIsLoading(false);
                  setIsRoutineUpdateAvailable(true);
                }}
              ></PrimaryButton>
              <PrimaryButton
                style={{
                  marginBottom: '10px',
                  minHeight: '30px',
                }}
                title={'Get Feedback on Last 10 Workouts'}
                onClick={async () => {
                  vm.onSendPress('Give me feedback on my last 10 workouts.');
                  setIsLoading(true);
                  const workouts = JSON.stringify(await vm.getWorkouts(10));
                  await vm.getFeedback(workouts, 10);
                  setIsLoading(false);
                  setIsRoutineUpdateAvailable(true);
                }}
              ></PrimaryButton>
            </>
          ) : (
            <>
              <TextMD
                style={{
                  margin: '10px 0px',
                }}
              >
                Describe the tone you'd like HevyAgent to use.
              </TextMD>
              <FormInput
                type="text"
                name="tone"
                id="tone"
                placeholder='i.e. "friendly", "professional", "military"'
                onChange={e => vm.onUpdatedChatbotTone(e.target.value)}
              ></FormInput>
              <TextMD
                style={{
                  margin: '10px 0px',
                }}
              >
                Sex
              </TextMD>
              <Select
                styles={{
                  valueContainer(base) {
                    return {
                      ...base,
                      backgroundColor: 'white',
                    };
                  },
                  dropdownIndicator(base) {
                    return {
                      ...base,
                      color: '#1780EA',
                    };
                  },
                  indicatorSeparator(base) {
                    return {
                      ...base,
                      backgroundColor: 'white',
                    };
                  },

                  placeholder(base) {
                    return {
                      ...base,
                      color: 'black',
                    };
                  },
                }}
                options={[
                  { value: 'Male', label: 'Male' },
                  { value: 'Female', label: 'Female' },
                ]}
                onChange={(e: any) => {
                  console.log(e.value);
                  vm.onUpdatedSex(e.value);
                }}
              ></Select>
              <TextMD
                style={{
                  margin: '10px 0px',
                }}
              >
                What's your primary goal?
              </TextMD>
              <Select
                styles={{
                  valueContainer(base) {
                    return {
                      ...base,
                      backgroundColor: 'white',
                    };
                  },
                  dropdownIndicator(base) {
                    return {
                      ...base,
                      color: '#1780EA',
                    };
                  },
                  indicatorSeparator(base) {
                    return {
                      ...base,
                      backgroundColor: 'white',
                    };
                  },
                  placeholder(base) {
                    return {
                      ...base,
                      color: 'black',
                    };
                  },
                }}
                options={[
                  { value: 'Lose Fat', label: 'Lose Fat' },
                  { value: 'Gain Muscle', label: 'Gain Muscle' },
                  { value: 'Increase Strength', label: 'Increase Strength' },
                ]}
                onChange={(e: any) => {
                  console.log(e.value);
                  vm.onUpdatedGoal(e.value);
                }}
              ></Select>
            </>
          )}
        </>
      )}
    </View>
  );
});
