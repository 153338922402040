import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: 'sk-proj-GAYjv6EPgj8ouW2sdQZBT3BlbkFJJTtxWdw3ElF4iZab54rO',
  dangerouslyAllowBrowser: true,
});

export const generateAIRoutines = async (
  userInput: string,
  updateProgress: (progress: number) => void,
) => {
  const assistant = await openai.beta.assistants.retrieve('asst_gCxxkJ05cqDRQvOrV3uhUs9D');

  const thread = await openai.beta.threads.create({
    messages: [
      {
        role: 'user',
        content: `${userInput}\nONLY INCLUDE THE ARRAY OF ROUTINES IN THE OUTPUT, NO OTHER TEXT OR SYMBOLS SHOULD BE ADDED BEFORE OR AFTER THE ARRAY.`,
      },
    ],
  });

  let output = '';

  const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
    assistant_id: assistant.id,
  });

  if (run.status === 'completed') {
    const messages = await openai.beta.threads.messages.list(run.thread_id);
    for (const message of messages.data.reverse()) {
      if (message.content[0].type === 'text') {
        console.log(`${message.content[0].text.value}`);
        output = message.content[0].text.value;
        updateProgress(100);
      }
    }
  } else {
    console.log(run.status);
  }
  return output;
};
